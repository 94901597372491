//
// Menu for small screens
//
(function () {
  var menuButton = document.querySelector('#menu-button');
  var menuList = document.querySelector('#picture-only-link');
  var languagesButton = document.querySelector('#languages-button');
  var languagesList = document.querySelector('#languages-menu');

  menuButton.addEventListener('click', function () {
    if (languagesList.style.display === 'block') {
      languagesList.style.display = 'none';
      languagesButton.classList.toggle('active');
    }

    if (menuList.style.display !== 'block') {
      menuList.style.display = 'block';
    } else {
      menuList.style.display = 'none';
    }

    menuButton.classList.toggle('active');
  });

  languagesButton.addEventListener('click', function () {
    if (menuList.style.display === 'block') {
      menuList.style.display = 'none';
      menuButton.classList.toggle('active');
    }
    if (languagesList.style.display !== 'block') {
      languagesList.style.display = 'block';
    } else {
      languagesList.style.display = 'none';
    }
    languagesButton.classList.toggle('active');
  });
})();

// Add placeholder event function for Simple Analytics sa_event
(function () {
  window.sa_event =
    window.sa_event ||
    function () {
      var a = [].slice.call(arguments);
      window.sa_event.q ? window.sa_event.q.push(a) : (window.sa_event.q = [a]);
    };
})();

//
// Share buttons
// FB, LinkedIn Reddit, Bluesky and copying a link
//
(function () {
  if (!document.querySelector('#share-buttons')) return;

  var fBButton = document.querySelector('#fb-button');
  var linkButton = document.querySelector('#link-button');
  var linkedInButton = document.querySelector('#linkedin-button');
  var redditButton = document.querySelector('#reddit-button');
  var blueskyButton = document.querySelector('#bluesky-button');

  linkButton.addEventListener('click', function () {
    var tempElement = document.createElement('input'),
      text = window.location.href;

    document.body.appendChild(tempElement);
    tempElement.value = text;
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);

    var messageElement = document.getElementById('social-copied-message');
    messageElement.innerText = 'Link copied!';

    messageElement.style.display = 'inline-block';
    setTimeout(function () {
      messageElement.style.display = 'none';
    }, 4000);

    sa_event && sa_event('link_copied');
  });

  fBButton.addEventListener('click', function () {
    const link = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    window.open(link, 'newwindow', 'width=500,height=350');

    sa_event && sa_event('share', { shareLocation: 'FB' });
  });

  linkedInButton.addEventListener('click', function () {
    const link = `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;
    window.open(link, '_blank').focus();

    sa_event && sa_event('share', { shareLocation: 'LinkedIn' });
  });

  redditButton.addEventListener('click', function () {
    const link = `https://www.reddit.com/submit?url=${window.location.href}`;
    window.open(link, '_blank').focus();

    sa_event && sa_event('share', { shareLocation: 'Reddit' });
  });

  blueskyButton.addEventListener('click', function () {
    const link = `https://bsky.app/intent/compose?text=${window.location.href}`;
    window.open(link, '_blank').focus();

    sa_event && sa_event('share', { shareLocation: 'Bluesky' });
  });
})();

//
// Language Variety switcher
// Navigate using JS so that bots don't follow the link
//
(function () {
  if (!document.querySelector('#english-lv-selector')) return;

  var usButton = document.querySelector('#lv-switcher-us-english');
  var ukButton = document.querySelector('#lv-switcher-uk-english');

  usButton.addEventListener('click', function () {
    const currentUrl = window.location.href;

    const updatedUrl = new URL(currentUrl);
    updatedUrl.hash = '';
    updatedUrl.searchParams.set('lv', 'us-english');

    window.location.href = updatedUrl.href;
  });
  ukButton.addEventListener('click', function () {
    const currentUrl = window.location.href;

    const updatedUrl = new URL(currentUrl);
    updatedUrl.hash = '';
    updatedUrl.searchParams.set('lv', 'uk-english');

    window.location.href = updatedUrl.href;
  });

  // Check if the element with id 'lv-switcher-uk-english' has the 'selected' class
  // If so update the URL to reflect the page state
  if (ukButton && ukButton.classList.contains('selected')) {
    // Add or modify URL parameter here
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('lv', 'uk-english');

    // Update the browser URL (without reloading the page)
    window.history.pushState({}, '', currentUrl);
  }
})();
